<template>
  <div class="content">
    <div class="title-box">
      <div class="time-box">时间</div>
      <div class="company-box">单位</div>
      <div class="personal-box">个人</div>
    </div>
    <div v-for="(item, idx) in info.details" :key="idx"
      v-bind:class="['center-box', idx % 2 == 0 ? 'item-bk' : '']"
    >
        <span class="time-box">
          <span>{{ item.begDate | yyyyMMFormat }}</span>
          <span>-</span>
          <span>{{ item.endDate | yyyyMMFormat}}</span>
        </span>
        <span class="company-box">{{ item.companyFee }}</span>
        <span class="personal-box">{{ item.personFee }}</span>
    </div>
    <div class="footer-box">
      <div class="time-box">合计</div>
      <div class="company-box">{{ info.companyFee }}</div>
      <div class="personal-box">{{ info.personFee }}</div>
    </div>
  </div>
</template>

<script>
import { Divider } from "vant";
export default {
  props: ["info"],
  components: {
    [Divider.name]: Divider,
  },
};
</script>

<style scoped>
.content {
  border: 1px solid #cccccc;
}

.title-box {
  height: 36px;
  background: #dbf0ff;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #658CF1;
  display: flex;
}

/* .title-box div {
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  margin-right: -1px;
} */

.time-box {
  width: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
}

.company-box {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
}

.personal-box {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #cccccc;
}

.center-box {
  height: 36px;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  height: 50px;
  color: #1a1a1a;
  display: flex;
}

/* .center-box span {
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  margin-right: -1px;
} */

.footer-box {
  height: 36px;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  color: #658CF1;
  display: flex;
}

/* .footer-box div {
  border-right: 1px solid #cccccc;
  margin-right: -1px;
} */

.item-bk {
  background: #f6f9fc;
}
</style>