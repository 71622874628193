<template>
  <div>
    <calc-detailed-page>
      <div class="center">
        <div class="title-box">
          合计应缴：<b>{{ res.amount }}</b
          >元
        </div>

        <div class="content-box">
          <pension-supplement-table-details :info="res"></pension-supplement-table-details>
        </div>

        <div class="button-box">以上结果只是测算，最终以社保局计算结果为准</div>
      </div>
    </calc-detailed-page>
  </div>
</template>

<script>
import CalcDetailedPage from "@/components/CalcDetailedPage";
import PensionSupplementTableDetails from "./PensionSupplementTableDetails";
import { Divider } from "vant";
export default {
  components: {
    CalcDetailedPage,
    PensionSupplementTableDetails,
    [Divider.name]: Divider,
  },
  mounted() {
    const res = this.$route.query.res;
    if (res != undefined) {
      this.res = JSON.parse(res);
    }
  },
  data() {
    return {
      res: {},
    };
  },
};
</script>

<style scoped>
.center {
  /* padding: 0 14px; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title-box {
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  color: #666666;
}

.title-box b {
  font-size: 33px;
  font-weight: bold;
  line-height: 40px;
  color: #658CF1;
  margin-right: 2px;
}

.center >>> .van-divider {
  margin: 0;
}

.content-box {
  padding: 10px 0;
}

.button-box {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #658CF1;
  margin: 50px 0 0 0;
}
</style>